
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "cooperative-admin-dashboard",
  components: {},
  data() {
    return {
      summary: {},
      claimsSummary: {},
      claims: [
        {
          insured: "",
          claimNumber: "",
          policyType: "",
          entity: "",
          lossType: "",
          status: "",
          amount: "",
          settledAmount: "",
          outstandingAmount: "",
          lossDate: "",
          reportedDate: "",
          publicId: "",
          hasClaimNumber: false,
          isSettlementOfferAccepted: false,
          settlementOffer: {},
          isDischargeVoucherApproved: false,
          businessTypeShortCode: "",
          settlementOffers: "",
        },
      ],
      avatarUrl: variables.AVATAR_ROUTE,
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageTitle("Cooperative Dashboard");
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    this.getDashboardStatistics();

    //Set page title
    document.title =
      "Cooperative Admin Dashboard | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getDashboardStatistics() {
      //Fetch list of cooperative members
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.COOPERATIVE_DASHBOARD_ROUTE)
          .then(({ data }) => {
            //Assign data to variables
            this.summary = data.data;
            this.claims = data.data.recentClaims;
            this.claimsSummary = data.data.claimsSummary.claimsSummary;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
  },
});
